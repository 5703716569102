import { useMutation } from "react-query";
import { axiosInstance } from "../services/axiosTokenInstance";

const usePut = (url, queryKey, id) => {
  const { isLoading, error, mutateAsync } = useMutation(
    queryKey,
    async (data) => {
      const result = await axiosInstance({
        method: "put",
        url: url,
        data: data,
        params: id,
      });
      return result.data;
    },
    {
      onError: (error) => {
        console.log(error);
      },
    }
  );

  return { isLoading, error, mutateAsync };
};

export default usePut;
