/* eslint-disable import/no-anonymous-default-export */

import axios from "axios";
import userGlobalConfig from "../utils/constants/globalConfig";
import { toast } from "react-toastify";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: undefined,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    // if token required then read the login token (ACCESS TOKEN) and pass that to headers
    const token = localStorage.getItem(userGlobalConfig.TOKEN);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status === 401) {
      toast.error("Session Expired");
      console.log("error from token instance ", error.response);
      localStorage.removeItem(userGlobalConfig.TOKEN);
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export { axiosInstance };
