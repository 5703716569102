/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import QUERY_KEYS from "../../services/queryKeys";
import ENDPOINTS from "../../services/endPoints";

import { toast } from "react-toastify";
import CustomModal from "../../components/customModal/customModal";
import useDelete from "../../hooks/useDelete";
import { RoutePath } from "../../routes/routePath";
import usePost from "../../hooks/usePost";
import useGet from "../../hooks/useGet";
import userGlobalConfig from "../../utils/constants/globalConfig";
import ImagesUrl from "../../utils/constants/images";

const UserDetail = () => {
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const nav = useNavigate();

  const { data: userDetailData, refetch: refetchUserDetail } = useGet(
    ENDPOINTS.USER_DETAIL,
    QUERY_KEYS.USER_DETAIL_QUERY_KEY,
    {
      user_id: JSON.parse(localStorage.getItem(userGlobalConfig.USER_ID)),
    }
  );
  const userData = userDetailData?.data;

  const { isLoading: isLoadingUserStatus, mutateAsync: userStatusMutateAsync } =
    usePost(ENDPOINTS.USER_STATUS, QUERY_KEYS.USER_STATUS_CHANGE_QUERY_KEY);
  const { isLoading: isLoadingUserDelete, mutateAsync: UserDeleteMutateAsync } =
    useDelete(ENDPOINTS.USER_DELETE, QUERY_KEYS.USER_DELETE_QUERY_KEY);
  const onDeleteConfirm = async () => {
    try {
      const response = await UserDeleteMutateAsync({
        user_id: deleteUserId,
      });
      if (response.status === true) {
        toast.success(response?.message);
        setDeleteUserModal(false);
        nav(RoutePath.USER_MANAGEMENT.path);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const handelDeleteUserModal = (id) => {
    setDeleteUserId(id);
    setDeleteUserModal(true);
  };
  const handleStatusChange = async (item) => {
    try {
      let statusCode = item.status === 1 ? "2" : "1";
      const response = await userStatusMutateAsync({
        _id: item._id,
        status: statusCode,
      });
      if (response.status === true) {
        toast.success(response?.message);
        refetchUserDetail();
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };
  return (
    <div className="container-fluid py-4">
      <div className="row">
        <div className="card position-relative">
          <img
            className="back_btn"
            src={ImagesUrl.back_btn}
            alt=".."
            onClick={() =>
              nav(RoutePath.USER_MANAGEMENT.path, {
                state: { headerName: RoutePath.USER_MANAGEMENT.title },
              })
            }
          />
          <div className="card-body">
            <div className="row">
              <div className="d-flex justify-content-end ">
                <td className="align-middle">
                  <span className="text-secondary text-md font-weight-bold  ">
                    <div className="cursor-pointer">
                      <Link
                        to={RoutePath.EDIT_USER_DETAIL.path}
                        state={{
                          headerName: RoutePath.EDIT_USER_DETAIL.title,
                          userDetail: userData,
                        }}
                      >
                        <i className="fas fa-pencil-alt text-dark px-1 "></i>
                      </Link>
                      <i
                        className="far fa-trash-alt  text-danger px-1  "
                        onClick={() => handelDeleteUserModal(userData?._id)}
                      ></i>
                    </div>
                  </span>
                </td>
                <span className="toggle-box px-1">
                  <input
                    onClick={() => handleStatusChange(userData)}
                    type="checkbox"
                    checked={userData?.status === 1 ? true : false}
                    id={userData?._id}
                    disabled={isLoadingUserStatus}
                  />
                  <label className="m-0" htmlFor={userData?._id} />
                </span>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label className="form-control-label">First name</label>
                  <input
                    className="form-control editInput"
                    readonly
                    disabled
                    value={userData?.first_name || "N/A"}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-control-label">Last name</label>
                  <input
                    className="form-control editInput"
                    readonly
                    disabled
                    value={userData?.last_name || "N/A"}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-control-label">Email address</label>
                  <input
                    className="form-control editInput"
                    readonly
                    disabled
                    value={userData?.email || "N/A"}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-control-label">Phone Number</label>
                  <input
                    className="form-control editInput"
                    readonly
                    disabled
                    value={
                      userData?.country_code && userData?.phone_number
                        ? `${userData?.country_code} ${userData?.phone_number}`
                        : "N/A"
                    }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-control-label">Status</label>
                  <input
                    className="form-control editInput"
                    readonly
                    disabled
                    value={userData?.status === 1 ? "Active" : "Suspended"}
                  />
                </div>
              </div>
            </div>
            <CustomModal
              customClass="sm-modal"
              open={deleteUserModal}
              title={"User Delete"}
              actionTitle={"Delete"}
              cancelTitle={"Cancel"}
              actionButtonClass={"bg-danger"}
              onConfirm={onDeleteConfirm}
              isLoading={isLoadingUserDelete}
              onClose={() => setDeleteUserModal(false)}
            >
              <div>
                <span>Are you sure, you want to delete user?</span>
              </div>
            </CustomModal>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetail;
