import React from "react";
import ExerciseCard from "../../components/UI/exerciseCard";
import StepListingSkeleton from "../../utils/skeleton/stepListingSkeleton";
import NotFound from "../../components/notFound";

export default function ExerciseList({ loading, list, onClick }) {
  // alert(typeof list?.data);
  return (
    <div className="flex-wrap-blk-new cols-1 cols-sm-2 cols-lg-4 cols-xl-4">
      {loading ? (
        <StepListingSkeleton />
      ) : !list?.data?.exercises_list > 0 ? (
        <NotFound content="Exercise List Not Found" />
      ) : (
        list?.data?.exercises_list?.map((item, index) => {
          return (
            <ExerciseCard
              data={item}
              key={index}
              onView={() => onClick(item._id)}
            />
          );
        })
      )}
    </div>
  );
}
