/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useRef, useState } from "react";
import ImagesUrl from "../../utils/constants/images";
import TempLayout from "../../components/UI/tempLayout";
import ScreenLayout from "../../components/UI/screenLayout";
import JournalComponent from "../../components/UI/journalComponent";
import { uniqueIdGenerator } from "../../utils/helpers";
import usePost from "../../hooks/usePost";
import ENDPOINTS from "../../services/endPoints";
import QUERY_KEYS from "../../services/queryKeys";
import { toast } from "react-toastify";
import { RoutePath } from "../../routes/routePath";
import { useNavigate } from "react-router-dom";

const WrapUp = ({ props }) => {
  const { data, templateId, handleEditForm, editTemplate, stepId } = props;
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const fileInput = useRef(null);
  const fileInput2 = useRef(null);
  const [flag, setFlag] = useState("red");

  const [form1, setForm1] = useState({
    formId: uniqueIdGenerator(),
    formType: "wrap Intro",
    image: {
      form_unique_id: "",
      added_image: "",
    },
    title: "Hey, you’ve reached the last step for this skill!",
    description: "Look at you go.",
    buttonText: "Continue Learning",
  });
  const [form2, setForm2] = useState({
    formId: uniqueIdGenerator(),
    formType: "primary",
    description:
      "One key takeaway will appear at a time. Digest each one and then press the arrow to move on.",
    buttonText: "Let’s begin",
  });
  const [wrapUpData, setWrapUpData] = useState([
    {
      id: uniqueIdGenerator(),
      title: "Takeaway #1",
      description:
        "What would you say to yourself if you were your own best friend who is struggling with low self-confidence?",
    },
  ]);
  const [wrapUpSubForm, setWrapUpSubForm] = useState({
    formId: uniqueIdGenerator(),
    formType: "Wrap Up",
    buttonText: "Done",
  });
  const [form4, setForm4] = useState({
    formId: uniqueIdGenerator(),
    formType: "Congrats",
    image: {
      form_unique_id: "",
      added_image: "",
    },
    title: "Congrats!",
    description: "You completed the Self-Confidence module. Fantastic effort!",
    buttonText: "I’m a star",
  });

  const [calculationForm, setCalculationForm] = useState({
    formId: uniqueIdGenerator(),
    formType: "quote",
    quote:
      "I always did something I was a little not ready to do. I think that’s how you grow. When there’s that moment of ‘Wow, I’m not really sure I can do this,’ and you push through those moments, that’s when you have a breakthrough.",
    quoteBy: "Marissa Mayer",
    buttonText: "Review Key Takeaways",
  });
  const [formData, setFormData] = useState(null);
  const nav = useNavigate();

  const { mutateAsync: addImagesMutateAsync, isLoading: isLoadingAddImages } =
    usePost(ENDPOINTS.TEMPLATE_IMAGES, QUERY_KEYS.TEMPLATE_IMAGES_QUERY_KEY);
  const {
    mutateAsync: addTemplateMutateAsync,
    isLoading: isLoadingAddTemplate,
  } = usePost(
    ENDPOINTS.TEMPLATE_DATA_ADD,
    QUERY_KEYS.TEMPLATE_DATA_ADD_QUERY_KEY
  );
  const handleFileInput = (target) => {
    setSelectedFile(target.files[0]);
    target.value = "";
  };
  const handleFileInput2 = (target) => {
    setSelectedFile2(target.files[0]);
    target.value = "";
  };

  const handleSelectUploadImage = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };
  const handleSelectUploadImage2 = () => {
    if (fileInput2.current) {
      fileInput2.current.click();
    }
  };

  const addNewWrapUp = () => {
    const length = wrapUpData.length + 1;
    setWrapUpData([
      ...wrapUpData,
      {
        id: uniqueIdGenerator(),
        title: `Reflection #${length}`,
        description:
          "What would you say to yourself if you were your own best friend who is struggling with low self-confidence?",
      },
    ]);
  };
  const handleWrapUpText = (event, index, key) => {
    setWrapUpData((prevResource) => {
      prevResource[index][key] = event.target.innerText;
      return [...prevResource];
    });
  };
  const removeWrapUp = (i) => {
    const newResource = [...wrapUpData];
    newResource.splice(i, 1);
    setWrapUpData(newResource);
  };
  const handleUploadImg1 = async (callback) => {
    if (selectedFile == null) {
      callback(null);
    } else {
      let imageId = form1?.image?.form_unique_id ?? "";
      const formData = new FormData();
      formData.append("step_id", stepId);
      formData.append("sub_step_id", data?._id);
      formData.append("template_id", templateId);
      formData.append("form_unique_id", imageId);
      formData.append("image", selectedFile);
      try {
        const response = await addImagesMutateAsync(formData);

        if (response.statusCode === 200) {
          callback(response);
        } else {
          toast.error(response?.message);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }
  };

  const handleUploadImg2 = async (callback) => {
    if (selectedFile2 == null) {
      callback(null);
    } else {
      let imageId = form4?.image?.form_unique_id ?? "";
      const formData = new FormData();
      formData.append("step_id", stepId);
      formData.append("sub_step_id", data?._id);
      formData.append("template_id", templateId);
      formData.append("form_unique_id", imageId);
      formData.append("image", selectedFile2);
      try {
        const response = await addImagesMutateAsync(formData);

        if (response.statusCode === 200) {
          callback(response);
        } else {
          toast.error(response?.message);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    }
  };

  const handleImgResponse1 = async (imageResponse) => {
    setForm1((previous) => ({
      ...previous,
      image: imageResponse?.data ?? previous.image,
    }));
    setSelectedFile(null);
    await handleUploadImg2(handleImgResponse2);
  };

  const handleImgResponse2 = async (imageResponse) => {
    setForm4((previous) => ({
      ...previous,
      image: imageResponse?.data ?? previous.image,
    }));
    setSelectedFile2(null);
    setFlag("yellow");
  };

  const uploadFormJson = async () => {
    const params = {
      step_id: stepId,
      sub_step_id: data?._id,
      template_id: templateId,
      template_json: formData,
    };

    try {
      const response = await addTemplateMutateAsync(params);

      if (response.statusCode === 200) {
        setFlag("red");
        toast.success(response?.message);
        handleEditForm();
        nav(RoutePath.SKILL_DETAIL.path);
      } else {
        toast.error(response?.message);
        setFlag("red");
      }
    } catch (error) {
      toast.error(error?.response.data?.message);
      setFlag("red");
    }
  };
  useEffect(() => {
    if (data?.template_id == 8) {
      const forms = data?.form_json;
      if (forms && forms?.length !== 0) {
        setForm1(forms[0]?.form_json[0]);
        setCalculationForm(forms[0]?.form_json[1]);
        setForm2(forms[0]?.form_json[2]);
        setWrapUpSubForm(forms[0]?.form_json[3]);
        setWrapUpData(forms[0]?.form_json[3]?.resources);
        setForm4(forms[0]?.form_json[4]);
      } else {
        handleEditForm(true);
      }
    }
  }, [data]);
  useEffect(() => {
    if (flag === "yellow") {
      setFormData([
        {
          name: data?.name ?? "Wrap Up",
          form_json: [
            form1,
            calculationForm,
            form2,
            { ...wrapUpSubForm, resources: wrapUpData },

            form4,
          ],
        },
      ]);
      setFlag("green");
    }
  }, [form1, form4]);

  useEffect(() => {
    if (flag === "green") uploadFormJson();
  }, [flag]);
  return (
    <TempLayout
      handleEditForm={handleEditForm}
      editTemplate={editTemplate}
      handleApply={() => handleUploadImg1(handleImgResponse1)}
      loading={isLoadingAddTemplate || isLoadingAddImages}
    >
      <section className="screen-section">
        <div className="container-fluid gx-0">
          <div className="row gap-3">
            <div className="col-12">
              <div className="screen-wrapper">
                <ScreenLayout name={data?.name} notHeaderRequire="true">
                  <div className="screen-body px-35">
                    <div className="upload-img-blk big-rect-box">
                      <img
                        className="preview-img"
                        src={
                          selectedFile === null
                            ? form1?.image?.added_image == ""
                              ? ImagesUrl.shape_image_square
                              : `https://leadherself-api.csdevhub.com/src/public/form_images/${form1?.image?.added_image}`
                            : URL.createObjectURL(selectedFile)
                        }
                        alt="Image"
                      />
                      <div className="upload-img-file">
                        <input
                          type="file"
                          className="d-none"
                          accept="image/*"
                          ref={fileInput}
                          onChange={({ target }) => handleFileInput(target)}
                        />
                        <label
                          htmlFor="ImageUpload"
                          onClick={
                            editTemplate ? handleSelectUploadImage : null
                          }
                        >
                          <i className="fa-solid fa-image" />
                        </label>
                      </div>
                    </div>
                    <div
                      className="checkbox-wrapper"
                      style={{ maxHeight: "290px", marginTop: "0px" }}
                    >
                      <div className="checkbox-list">
                        <div className="screen-content">
                          <p
                            className="ls-font ft-22 lht-32"
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setForm1({
                                ...form1,
                                title: e.target.innerText,
                              });
                            }}
                          >
                            {form1?.title}
                          </p>
                          <p
                            className="ft-14 mt-3"
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setForm1({
                                ...form1,
                                description: e.target.innerText,
                              });
                            }}
                          >
                            {form1?.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="screen-footer">
                    <div className="footer-nav-btn">
                      <div
                        className="btn-text-style py-0"
                        contentEditable={editTemplate}
                        onBlur={(e) => {
                          setForm1({
                            ...form1,
                            buttonText: e.target.innerText,
                          });
                        }}
                      >
                        {form1?.buttonText}
                      </div>
                    </div>
                  </div>
                </ScreenLayout>
                <ScreenLayout name={data?.name}>
                  <div className="scrollwrap">
                    <div className="col-grid">
                      <div className="col-grid-item">
                        <span>11</span>
                        <p>Steps completed</p>
                      </div>
                      <div className="col-grid-item">
                        <span>16</span>
                        <p>Questions answered</p>
                      </div>
                      <div className="col-grid-item">
                        <span>3</span>
                        <p>Actions performed</p>
                      </div>
                      <div className="col-grid-item">
                        <span>4</span>
                        <p>Resources explored</p>
                      </div>
                      <div className="col-grid-item">
                        <span>5</span>
                        <p>Behaviors examined</p>
                      </div>
                      <div className="col-grid-item">
                        <span>1</span>
                        <p>Key skill improved</p>
                      </div>
                    </div>
                    <div className="screen-body">
                      <div className="quote-wrapper">
                        <div className="quote-icon">
                          <img src={ImagesUrl.quote_icon} alt="icon" />
                        </div>
                        <div
                          className="checkbox-wrapper"
                          style={{ maxHeight: "200px", marginTop: "0px" }}
                        >
                          <div className="checkbox-list">
                            <div className="quote-content">
                              <p
                                contentEditable={editTemplate}
                                onBlur={(e) => {
                                  setCalculationForm({
                                    ...calculationForm,
                                    quote: e.target.innerText,
                                  });
                                }}
                              >
                                {calculationForm?.quote}
                              </p>
                            </div>
                            <div className="quote-by d-flex justify-content-center">
                              <h3
                                contentEditable={editTemplate}
                                onBlur={(e) => {
                                  setCalculationForm({
                                    ...calculationForm,
                                    quoteBy: e.target.innerText,
                                  });
                                }}
                              >
                                {calculationForm?.quoteBy}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="screen-footer">
                    <div className="footer-nav-btn">
                      <div
                        className="btn-style"
                        contentEditable={editTemplate}
                        onBlur={(e) => {
                          setCalculationForm({
                            ...calculationForm,
                            buttonText: e.target.innerText,
                          });
                        }}
                      >
                        {calculationForm?.buttonText}
                      </div>
                    </div>
                  </div>
                </ScreenLayout>
                <ScreenLayout name={data?.name}>
                  <div className="screen-body">
                    <div
                      className="checkbox-wrapper"
                      style={{ maxHeight: "395px", marginTop: "0px" }}
                    >
                      <div className="checkbox-list">
                        <div className="screen-content">
                          <p
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setForm2({
                                ...form2,
                                description: e.target.innerText,
                              });
                            }}
                          >
                            {form2.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="screen-footer">
                    <div className="footer-nav-btn">
                      <div
                        className="btn-style"
                        contentEditable={editTemplate}
                        onBlur={(e) => {
                          setForm2({
                            ...form2,
                            buttonText: e.target.innerText,
                          });
                        }}
                      >
                        {form2.buttonText}
                      </div>
                    </div>
                  </div>
                </ScreenLayout>
                <ScreenLayout name={data?.name}>
                  <div className="screen-body">
                    <div className="add-item-blk">
                      <button
                        className="add-item-btn"
                        onClick={editTemplate ? addNewWrapUp : null}
                      >
                        Add More
                      </button>
                    </div>
                    <div class="reflection-wrapper">
                      {wrapUpData?.map((item, index, array) => {
                        return (
                          <JournalComponent
                            index={index}
                            color="#E6EBEC"
                            handleJournalText={handleWrapUpText}
                            item={item}
                            editTemplate={editTemplate}
                            removeJournal={removeWrapUp}
                            array={array}
                          />
                        );
                      })}
                    </div>
                  </div>
                  <div className="screen-footer">
                    <div className="footer-nav-btn">
                      <div
                        className="btn-style"
                        contentEditable={editTemplate}
                        onBlur={(e) => {
                          setWrapUpSubForm({
                            ...wrapUpSubForm,
                            buttonText: e.target.innerText,
                          });
                        }}
                      >
                        {wrapUpSubForm?.buttonText}
                      </div>
                    </div>
                  </div>
                </ScreenLayout>
                <ScreenLayout name={data?.name} notHeaderRequire="true">
                  <div className="screen-body px-35 item-center">
                    <div className="upload-img-blk">
                      <img
                        className="preview-img"
                        src={
                          selectedFile2 === null
                            ? form4?.image?.added_image == ""
                              ? ImagesUrl.shape_image
                              : `https://leadherself-api.csdevhub.com/src/public/form_images/${form4?.image?.added_image}`
                            : URL.createObjectURL(selectedFile2)
                        }
                        alt="Image"
                      />
                      <div className="upload-img-file">
                        <input
                          type="file"
                          className="d-none"
                          accept="image/*"
                          ref={fileInput2}
                          onChange={({ target }) => handleFileInput2(target)}
                        />
                        <label
                          htmlFor="ImageUpload"
                          onClick={
                            editTemplate ? handleSelectUploadImage2 : null
                          }
                        >
                          <i className="fa-solid fa-image" />
                        </label>
                      </div>
                    </div>
                    <div
                      className="checkbox-wrapper"
                      style={{ maxHeight: "290px", marginTop: "0px" }}
                    >
                      <div className="checkbox-list">
                        <div className="screen-content d-flex justify-content-center w-100 ">
                          <p
                            className="ls-font ft-22 lht-32 pb-2 "
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setForm4({
                                ...form4,
                                title: e.target.innerText,
                              });
                            }}
                          >
                            {form4.title}
                          </p>
                        </div>
                        <div className="screen-content">
                          <p
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setForm4({
                                ...form4,
                                description: e.target.innerText,
                              });
                            }}
                          >
                            {form4.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="screen-footer">
                    <div className="footer-nav-btn">
                      <div
                        className="btn-style"
                        contentEditable={editTemplate}
                        onBlur={(e) => {
                          setForm4({
                            ...form4,
                            buttonText: e.target.innerText,
                          });
                        }}
                      >
                        {form4.buttonText}
                      </div>
                    </div>
                  </div>
                </ScreenLayout>
                {/* <ScreenLayout name={data?.name} notHeaderRequire="true">
                  <div className="screen-body px-35 item-center">
                    <div className="upload-img-blk">
                      <img
                        className="preview-img"
                        src={
                          selectedFile3 === null
                            ? finalForm?.image?.added_image === ""
                              ? ImagesUrl.shape_image
                              : `https://leadherself-api.csdevhub.com/src/public/form_images/${finalForm?.image?.added_image}`
                            : URL.createObjectURL(selectedFile3)
                        }
                        alt="Image"
                      />
                      <div className="upload-img-file">
                        <input
                          type="file"
                          className="d-none"
                          accept="image/*"
                          ref={fileInput3}
                          onChange={({ target }) => handleFileInput3(target)}
                        />
                        <label
                          htmlFor="ImageUpload"
                          onClick={
                            editTemplate ? handleSelectUploadImage3 : null
                          }
                        >
                          <i className="fa-solid fa-image" />
                        </label>
                      </div>
                    </div>
                    <div
                      className="checkbox-wrapper"
                      style={{ maxHeight: "264px", marginTop: "0px" }}
                    >
                      <div className="checkbox-list">
                        <div className="screen-content d-flex justify-content-center w-100 ">
                          <p
                            className="ls-font ft-22 lht-32 pb-2 "
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setFinalForm({
                                ...finalForm,
                                title: e.target.innerText,
                              });
                            }}
                          >
                            {finalForm.title}
                          </p>
                        </div>
                        <div className="screen-content">
                          <p
                            contentEditable={editTemplate}
                            onBlur={(e) => {
                              setFinalForm({
                                ...finalForm,
                                description: e.target.innerText,
                              });
                            }}
                          >
                            {finalForm.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="screen-footer">
                    <div
                      className="btn-text-style py-2"
                      contentEditable={editTemplate}
                      onBlur={(e) => {
                        setFinalForm({
                          ...finalForm,
                          returnHomeButtonText: e.target.innerText,
                        });
                      }}
                    >
                      {finalForm.returnHomeButtonText}
                    </div>
                    <div className="footer-nav-btn">
                      <div
                        className="btn-style"
                        contentEditable={editTemplate}
                        onBlur={(e) => {
                          setFinalForm({
                            ...finalForm,
                            buttonText: e.target.innerText,
                          });
                        }}
                      >
                        {finalForm.buttonText}
                      </div>
                    </div>
                  </div>
                </ScreenLayout> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </TempLayout>
  );
};

export default WrapUp;
