const icons = {
  searchIcon: "",
  mobile_Indicator: "assets/images/mobile-indicator.svg",
  back_arrow: "assets/images/back-arrow-icon.svg",
  shape_image: "assets/images/shape-image.png",
  range_slider: "assets/images/range-slider-1.png",
  shape_image_square: "assets/images/squarImg.png",
  back_btn: "assets/images/back.png",
  quote_icon: "assets/images/quote.svg",
};

const logos = {
  appLogo: "",
};

const backgrounds = {};

const images = {};

const placeHolders = {};

const ImagesUrl = {
  ...icons,
  ...images,
  ...backgrounds,
  ...logos,
  ...placeHolders,
};

export default ImagesUrl;
