/* eslint-disable import/no-anonymous-default-export */
import Journal from "./journal";
import Scenario from "./scenario";
import Actions from "./actions";
import BehavioralTendencies from "./behavioralTendencies";
import FinalWrapUp from "./finalWrapUp";
import Intro from "./intro";
import JourneyJournal from "./journeyJournal";
import JourneyQuiz from "./journeyQuiz";
import RealityCheck from "./realityCheck";
import Reflection from "./reflection";
import SkillQuiz from "./skillQuiz";
import WrapUp from "./wrapUp";

const TemplateIndex = ({
  index,
  data,
  stepId,
  handleEditForm,
  editTemplate,
  dataLoading,
  refetchStepDetail,
}) => {
  const templateId = index;
  const props = {
    templateId,
    data,
    stepId,
    handleEditForm,
    editTemplate,
    dataLoading,
    refetchStepDetail,
  };
  const tempArr = [
    <Intro props={props} />,
    <RealityCheck props={props} />,
    <BehavioralTendencies props={props} />,
    <Scenario props={props} />,
    <Actions props={props} />,
    <Reflection props={props} />,
    <SkillQuiz props={props} />,
    <Journal props={props} />,
    <WrapUp props={props} />,
    <JourneyQuiz props={props} />,
    <JourneyJournal props={props} />,
    <FinalWrapUp props={props} />,
  ];

  return tempArr[index];
};

export default TemplateIndex;
