import JourneyDetail from "./JourneyManagement/journeyDetail";
import JourneyList from "./JourneyManagement/journeyList";
import LoginScreen from "./auth/login";
import ContentList from "./contentManagement/contentList";
import DashboardScreen from "./dashboard";
import ExerciseDetail from "./exerciseManagement/exerciseDetail";
import SkillDetail from "./skillManagement/skillDetail";
import SkillsList from "./skillManagement/skillsList";
import StepDetail from "./stepsManagement/stepDetail";
import EditUserDetail from "./userManagement/editUserDetail";
import UserDetail from "./userManagement/userDetail";
import UserListing from "./userManagement/userListing";

const ScreenStack = {
  DASHBOARD: DashboardScreen,
  LOGIN: LoginScreen,

  USER_MANAGEMENT: UserListing,
  USER_DETAIL: UserDetail,
  EDIT_USER_DETAIL: EditUserDetail,

  JOURNEY_MANAGEMENT: JourneyList,
  JOURNEY_DETAIL: JourneyDetail,

  SKILLS_MANAGEMENT: SkillsList,
  SKILL_DETAIL: SkillDetail,
  STEPS_DETAIL: StepDetail,
  EXERCISE_DETAIL: ExerciseDetail,

  CONTENT_MANAGEMENT: ContentList,
};

export default ScreenStack;
