import React from "react";
import { ClipLoader } from "react-spinners";

export default function CustomModal({
  open,
  onClose,
  onConfirm,
  title,
  cancelTitle,
  actionTitle,
  actionButtonClass,
  children,
  isLoading,
  customClass,
  actionButtonNotRequire,
}) {
  return (
    <div
      className={`modal fade common-modal ${customClass} ${open ? "show" : ""}`}
      id="exampleModalCenter"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      style={open ? { display: "block", justifyContent: "center" } : {}}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-body p-0">
            <div className="common-body-modal">
              {/* <buttonfont-weight-bolder
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              ></buttonfont-weight-bolder> */}
              <div className="common-modal-header">
                <h5 className="common-modal-title">{title}</h5>
              </div>
              <div className="common-modal-form">{children}</div>
              <div className="common-modal-footer  ">
                <button
                  type="button"
                  className="btn btn-green mb-0 text-white"
                  data-bs-dismiss="modal"
                  onClick={onClose}
                >
                  {cancelTitle}
                </button>
                {actionButtonNotRequire ? null : (
                  <button
                    type="button"
                    className={`btn  mb-0 text-white ${actionButtonClass}`}
                    onClick={onConfirm}
                  >
                    {isLoading ? (
                      <ClipLoader color="#0d6efd" size={15} />
                    ) : (
                      actionTitle
                    )}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
