import { useQuery } from "react-query";
import { axiosInstance } from "../services/axiosTokenInstance";

const useGet = (url, queryKey, params) => {
  const { data, error, isLoading, isError, isFetching, isSuccess, refetch } =
    useQuery(queryKey, async () => {
      const result = await axiosInstance({
        method: "get",
        url: url,
        params: params,
        headers: { "Content-Type": "application/json" },
      });
      return result.data;
    });

  return { data, error, isLoading, isError, isFetching, isSuccess, refetch };
};

export default useGet;
