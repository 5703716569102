import React from "react";

const CustomErrorBoundary = ({ content }) => {
  return (
    <div className="page_not_found">
      <div className="number">Oops!</div>
      <div className="text">
        <br />
        something went wrong
      </div>
    </div>
  );
};

export default CustomErrorBoundary;
