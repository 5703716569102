import React, { useState } from "react";
import { SettingArray } from "../../utils/seeds/settingsArray";
import AboutUs from "./aboutUs";
import Support from "./support";
import TermsAndCond from "./termsAndCond";
import PrivacyPolicy from "./privacyPolicy";

const ContentList = () => {
  const [templateActive, setTemplateActive] = useState(0);
  const handelActiveTemplate = (index) => {
    setTemplateActive(index);
    window.scrollTo(0, 300);
  };

  const ContentType = () => {
    if (templateActive === 0) {
      return <AboutUs />;
    } else if (templateActive === 1) {
      return <Support />;
    } else if (templateActive === 2) {
      return <TermsAndCond />;
    } else if (templateActive === 3) {
      return <PrivacyPolicy />;
    }
  };
  return (
    <div>
      <div className="content-card-wrapper">
        <div className="row row-cols-2 row-cols-sm-2 row-cols-lg-5 row-cols-xl-4 gy-4">
          {SettingArray?.map((item, index) => {
            return (
              <div
                className="col cursor-pointer"
                key={index}
                onClick={() => handelActiveTemplate(index)}
              >
                <div
                  className={templateActive == index ? "card active" : "card"}
                  style={{ height: "100%" }}
                >
                  <div className="card-header  text-center">
                    <div className="icon icon-shape icon-sm bg-gradient-primary shadow text-center border-radius-sm">
                      {item.icon}
                    </div>
                  </div>
                  <div className="card-body p-2 m-0 text-center">
                    <h6 className="text-center ">{item.name}</h6>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <ContentType />
    </div>
  );
};

export default ContentList;
