import React from "react";
import ImagesUrl from "../../utils/constants/images";

const ScreenLayout = ({
  name,
  children,
  notHeaderRequire,
  notIndicatorRequire,
}) => {
  return (
    <div className="screen-layout">
      <div className="screen-indicator">
        <span className="time-indicator">9:41</span>
        {notIndicatorRequire ? null : (
          <span className="mobile-indicator">
            <img src={ImagesUrl.mobile_Indicator} alt="Indicator" />
          </span>
        )}
      </div>
      {notHeaderRequire ? null : (
        <div className="screen-header">
          <span className="back-btn">
            <img src={ImagesUrl.back_arrow} alt="Arrow" />
          </span>
          <h2>{name}</h2>
        </div>
      )}
      {children}
      <div className="footer-bar-blk">
        <span className="footer-bar" />
      </div>
    </div>
  );
};

export default ScreenLayout;
