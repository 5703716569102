/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import { useFormik } from "formik";
import {
  loginInitialValues,
  loginValidationSchema,
} from "../../../utils/validations/login";
import { toast } from "react-toastify";
import { useContext, useState } from "react";
import { AuthContext } from "../../../contexts/authContext";

import ENDPOINTS from "../../../services/endPoints";
import usePost from "../../../hooks/usePost";
import QUERY_KEYS from "../../../services/queryKeys";
import { ClipLoader } from "react-spinners";

const LoginScreen = () => {
  const { login } = useContext(AuthContext);
  const [passwordHide, setPasswordHide] = useState(true);
  const handlePassword = () => {
    setPasswordHide(!passwordHide);
  };
  const {
    mutateAsync: adminLoginMutateAsync,
    isLoading: isLoadingAdminLogin,
    error: errorAdminLogin,
  } = usePost(ENDPOINTS.ADMIN_LOGIN, QUERY_KEYS.ADMIN_LOGIN_QUERY_KEY);
  const {
    errors,
    handleChange,
    handleBlur,
    values,
    touched,
    resetForm,
    handleSubmit,
  } = useFormik({
    initialValues: loginInitialValues,
    validationSchema: loginValidationSchema,
    onSubmit: async (values) => {
      const data = {
        email: values.email,
        password: values.password,
      };
      try {
        const response = await adminLoginMutateAsync(data);
        if (response.status === true) {
          toast.success(response.message);
          login(response?.data?.token);
          resetForm();
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        toast.error("Invalid email or password !");
      }
    },
  });
  return (
    <div>
      <main className="main-content  mt-0">
        <section>
          <div className="page-header min-vh-100">
            <div className="container">
              <div className="row">
                <div className="col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0 mx-auto">
                  <div className="card card-plain">
                    <div className="card-header pb-0 text-start">
                      <h4 className="main-heading">Log In</h4>
                      <p className="mb-0">
                        Enter your email and password to login !
                      </p>
                    </div>
                    <div className="card-body">
                      <form>
                        <div className="mb-3">
                          <label>Email</label>
                          <input
                            type="email"
                            className="form-control form-control-lg"
                            placeholder="Email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>{" "}
                        {touched.email && errors.email && (
                          <p className="error">{errors.email}</p>
                        )}
                        <div className="mb-3 position-relative">
                          <label>Password</label>
                          <input
                            type={passwordHide ? "password" : "text"}
                            className="form-control form-control-lg  "
                            placeholder="Password"
                            name="password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          <div
                            className="position-absolute top-50  end-3 "
                            onClick={handlePassword}
                          >
                            {passwordHide ? (
                              <img
                                src="../assets/logos/visibility_off.svg"
                                alt="..."
                                width="17px"
                              />
                            ) : (
                              <img
                                src="../assets/logos/visibility_on.svg"
                                alt="..."
                                width="17px"
                              />
                            )}
                          </div>
                        </div>{" "}
                        {touched.password && errors.password && (
                          <p className="error">{errors.password}</p>
                        )}
                        <div className="text-center">
                          <button
                            type="button"
                            className="btn btn-lg btn-green  w-100 mt-3 mb-0"
                            onClick={() => handleSubmit()}
                            disabled={isLoadingAdminLogin}
                          >
                            {isLoadingAdminLogin ? (
                              <ClipLoader color="#0d6efd" size={15} />
                            ) : (
                              "Login"
                            )}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-6 d-lg-flex d-none h-100 my-auto pe-0 position-absolute top-0 end-0 text-center justify-content-center flex-column">
                  <div className="position-relative bg-gradient-primary h-100 m-3 px-7 border-radius-lg d-flex flex-column justify-content-center overflow-hidden">
                    <img
                      className="login_logo_img"
                      src="./assets/logos/main-logo.svg"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default LoginScreen;
