/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useRef, useState } from "react";
import StepsList from "../stepsManagement/stepsList";
import CustomModal from "../../components/customModal/customModal";
import useDelete from "../../hooks/useDelete";
import ENDPOINTS from "../../services/endPoints";
import QUERY_KEYS from "../../services/queryKeys";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import {
  addSkillInitialValues,
  addSkillValidationSchema,
} from "../../utils/validations/skill";
import useGet from "../../hooks/useGet";
import userGlobalConfig from "../../utils/constants/globalConfig";
import usePut from "../../hooks/usePut";
import usePost from "../../hooks/usePost";
import DetailSkeleton from "../../utils/skeleton/detailsSkeleton";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../routes/routePath";
import ImagesUrl from "../../utils/constants/images";

const SkillDetail = () => {
  const [deleteSkillModal, setDeleteSkillModal] = useState(false);
  const [deleteSkillId, setDeleteSkillId] = useState(null);
  const [editSkillModal, setEditSkillModal] = useState(false);
  const [uploadBackgroundImg, setUploadBackgroundImg] = useState(null);
  const [uploadImg, setUploadImg] = useState(null);
  const uploadImageRef = useRef(null);
  const uploadBackgroundImageRef = useRef(null);
  const nav = useNavigate();
  const {
    data: skillDetailData,
    isLoading: isLoadingSkillDetail,
    refetch: refetchSkillDetail,
  } = useGet(ENDPOINTS.SKILL_DETAIL, QUERY_KEYS.SKILL_DETAIL_QUERY_KEY, {
    _id: JSON.parse(localStorage.getItem(userGlobalConfig?.SKILL_ID)),
    page: 1,
    limit: 10,
  });
  const { mutateAsync: editSkillMutateAsync, isLoading: isLoadingEditSkill } =
    usePut(ENDPOINTS.SKILL_EDIT, QUERY_KEYS.SKILL_EDIT_QUERY_KEY);
  const skillData = skillDetailData?.data;
  const {
    isLoading: isLoadingSkillStatus,
    mutateAsync: skillStatusMutateAsync,
  } = usePost(ENDPOINTS.SKILL_STATUS, QUERY_KEYS.SKILL_STATUS_QUERY_KEY);
  const {
    isLoading: isLoadingSkillDelete,
    mutateAsync: SkillDeleteMutateAsync,
  } = useDelete(ENDPOINTS.SKILL_DELETE, QUERY_KEYS.SKILL_DELETE_QUERY_KEY);
  const onDeleteConfirm = async () => {
    try {
      const response = await SkillDeleteMutateAsync({
        _id: deleteSkillId,
      });
      if (response.status === true) {
        toast.success(response?.message);
        setDeleteSkillModal(false);
        nav(RoutePath.JOURNEY_DETAIL.path);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const handelDeleteSkillModal = (id) => {
    setDeleteSkillId(id);
    setDeleteSkillModal(true);
  };
  const {
    errors,
    handleBlur,
    handleChange,
    touched,
    resetForm,
    handleSubmit,
    values,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: addSkillInitialValues,
    validationSchema: addSkillValidationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("_id", skillData?._id);
      formData.append("name", values?.skillName);
      formData.append("description", values?.skillDescription);
      formData.append("image", values?.skillImage);
      formData.append("background_image", values?.skillBackgroundImg);
      try {
        const response = await editSkillMutateAsync(formData);
        if (response.statusCode === 200) {
          toast.success(response?.message);
          setEditSkillModal(false);
          resetForm();
          refetchSkillDetail();
        } else {
          toast.error(response?.message);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
  });

  const onChangeFile = (target) => {
    const selectedFile = target.files[0];
    setUploadImg(URL.createObjectURL(selectedFile));
    setFieldValue("skillImage", selectedFile);
    target.value = "";
  };
  const handleSelectUploadImage = () => {
    if (uploadImageRef.current) {
      uploadImageRef.current.click();
    }
  };
  const handelRemoveUploadImg = () => {
    setUploadImg(null);
    setFieldValue("skillImage", "");
  };
  const onChangeBackgroundFile = (target) => {
    const selectedFile = target.files[0];
    setUploadBackgroundImg(URL.createObjectURL(selectedFile));
    setFieldValue("skillBackgroundImg", selectedFile);
    target.value = "";
  };
  const handleSelectBackgroundUploadImage = () => {
    if (uploadBackgroundImageRef.current) {
      uploadBackgroundImageRef.current.click();
    }
  };
  const handelRemoveBackgroundUploadImg = () => {
    setUploadBackgroundImg(null);
    setFieldValue("skillBackgroundImg", "");
  };
  const handleStatusChange = async (item) => {
    try {
      let statusCode = item.status === 1 ? "2" : "1";
      const response = await skillStatusMutateAsync({
        _id: item?._id,
        status: statusCode,
      });
      if (response.status === true) {
        toast.success(response?.message);
        refetchSkillDetail();
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };
  useEffect(() => {
    setValues({
      skillBackgroundImg: skillData?.background_image,
      skillDescription: skillData?.description,
      skillImage: skillData?.image,
      skillName: skillData?.name,
    });
  }, [skillData, editSkillModal]);

  return (
    <div>
      <div className="row px-3">
        {isLoadingSkillDetail ? (
          <DetailSkeleton />
        ) : (
          <div className="card position-relative">
            <img
              className="back_btn"
              src={ImagesUrl.back_btn}
              alt=".."
              onClick={() =>
                nav(RoutePath.JOURNEY_DETAIL.path, {
                  state: { headerName: RoutePath.JOURNEY_DETAIL.title },
                })
              }
            />
            <div className="card-body">
              <div className="row gy-3">
                <div className="col-12">
                  <div className="d-flex justify-content-end align-items-center gap-1">
                    <i
                      className="fas fa-pencil-alt text-dark px-1 cursor-pointer "
                      onClick={() => setEditSkillModal(true)}
                    ></i>
                    <i
                      className="far fa-trash-alt  text-danger px-1 cursor-pointer  "
                      onClick={() => handelDeleteSkillModal(skillData?._id)}
                    ></i>
                    <span className="toggle-box px-1">
                      <input
                        onClick={() => handleStatusChange(skillData)}
                        type="checkbox"
                        checked={skillData?.status === 1 ? true : false}
                        id={1}
                        disabled={isLoadingSkillStatus}
                      />
                      <label className="m-0" htmlFor={1} />
                    </span>
                  </div>
                </div>
                <div className="col-12">
                  <div className="row">
                    <div className="col-sm-4 col-lg-3">
                      <div className="full-image-blk">
                        <img src={skillData?.image} alt="Image" />
                      </div>
                    </div>
                    <div className="col-sm-8 col-lg-9">
                      <div className="content-blk">
                        <h1 className="h2">{skillData?.name}</h1>
                        <p>{skillData?.description}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <StepsList />
      <CustomModal
        open={editSkillModal}
        title={"Edit Skill"}
        actionTitle={"Edit"}
        cancelTitle={"Cancel"}
        actionButtonClass={"bg-danger"}
        onConfirm={handleSubmit}
        isLoading={isLoadingEditSkill}
        onClose={() => {
          setEditSkillModal(false);
        }}
      >
        <div>
          <form>
            <div className="upload_container">
              <div>
                <div className="drag-input-field">
                  <label>Upload Image </label>
                  <input
                    type="file"
                    accept="image/*"
                    name="skillImage"
                    onBlur={handleBlur}
                    onChange={({ target }) => {
                      onChangeFile(target);
                    }}
                    ref={uploadImageRef}
                  />
                  {values?.skillImage?.length === 0 ? (
                    <label className="m-0" onClick={handleSelectUploadImage}>
                      <span className="upload-text">
                        <i className="fa-solid fa-cloud-arrow-up" />
                        Upload
                      </span>
                    </label>
                  ) : (
                    <div className="position-relative ">
                      <img
                        src={uploadImg === null ? values.skillImage : uploadImg}
                        style={{
                          height: "200px",
                          width: "100%",
                          objectFit: "cover",
                          borderRadius: "5px",
                        }}
                        alt="..."
                      />
                      <button
                        className="removeimg"
                        type="button"
                        onClick={() => handelRemoveUploadImg()}
                      >
                        X
                      </button>
                    </div>
                  )}
                </div>
                {touched.skillImage && errors.skillImage && (
                  <p className="error" style={{ marginTop: "4px" }}>
                    {errors.skillImage}
                  </p>
                )}
              </div>
              <div>
                <div className="mb-3">
                  <label> Name</label>
                  <input
                    type="text"
                    className="form-control form-control-lg"
                    placeholder="Enter Name"
                    name="skillName"
                    value={values.skillName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {touched.skillName && errors.skillName && (
                  <p className="error">{errors.skillName}</p>
                )}
                <div className="mb-3">
                  <label> Description </label>
                  <textarea
                    className="form-control form-control-lg"
                    placeholder="Enter  Description"
                    rows="3"
                    cols="50"
                    name="skillDescription"
                    value={values.skillDescription}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></textarea>
                </div>
                {touched.skillDescription && errors.skillDescription && (
                  <p className="error">{errors.skillDescription}</p>
                )}
              </div>
            </div>

            {/* <div className="drag-input-field mt-3">
              <label>Upload Background Image </label>
              <input
                type="file"
                accept="image/*"
                name="skillBackgroundImg"
                onBlur={handleBlur}
                onChange={({ target }) => {
                  onChangeBackgroundFile(target);
                }}
                ref={uploadBackgroundImageRef}
              />
              {values?.skillBackgroundImg?.length === 0 ? (
                <label
                  className="m-0"
                  onClick={handleSelectBackgroundUploadImage}
                >
                  <span className="upload-text">
                    <i className="fa-solid fa-cloud-arrow-up" />
                    Upload image
                  </span>
                </label>
              ) : (
                <div className="position-relative ">
                  <img
                    src={
                      uploadBackgroundImg === null
                        ? values.skillBackgroundImg
                        : uploadBackgroundImg
                    }
                    style={{
                      height: "200px",
                      width: "100%",
                      objectFit: "cover",
                      borderRadius: "5px",
                    }}
                    alt="..."
                  />
                  <button
                    className="removeimg"
                    type="button"
                    onClick={() => handelRemoveBackgroundUploadImg()}
                  >
                    X
                  </button>
                </div>
              )}
            </div>
            {touched.skillImage && errors.skillImage && (
              <p className="error" style={{ marginTop: "4px" }}>
                {errors.skillImage}
              </p>
            )} */}
          </form>
        </div>
      </CustomModal>
      <CustomModal
        customClass="sm-modal"
        open={deleteSkillModal}
        title={"Skill Delete"}
        actionTitle={"Delete"}
        cancelTitle={"Cancel"}
        actionButtonClass={"bg-danger"}
        onConfirm={onDeleteConfirm}
        isLoading={isLoadingSkillDelete}
        onClose={() => setDeleteSkillModal(false)}
      >
        <div>
          <span>Are you sure, you want to delete skill?</span>
        </div>
      </CustomModal>
    </div>
  );
};

export default SkillDetail;
