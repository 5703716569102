import React from "react";

function Dropdown({
  selectedOption,
  handleChange,
  options,
  handleBlur,
  defaultValue,
}) {
  return (
    <select
      className="form-control form-control-lg"
      value={selectedOption}
      onChange={handleChange}
      handleBlur={handleBlur}
    >
      <option value="" selected disabled hidden>
        {defaultValue}
      </option>
      {options?.map((option) => (
        <option key={option?._id} value={option._id}>
          {option?.title}
        </option>
      ))}
    </select>
  );
}

export default Dropdown;
