import React from "react";

const QuestionOptions = ({
  i,
  data,
  editTemplate,
  item,
  index,
  handleQuestionForm,
  handleQuestionAns,
}) => {
  return (
    <div className="checkbox-list" key={i}>
      <input
        type="radio"
        className="d-none"
        name={`listradio_${index}`}
        id={`checkbox_${index}${i}`}
        value={i + 1}
        onChange={(e) =>
          editTemplate ? handleQuestionAns(e, index, "answer") : null
        }
        checked={i + 1 == item.answer}
      />
      <label htmlFor={`checkbox_${index}${i}`}>
        <span className="check-box-icon" />
        <span
          className="check-box-text"
          contentEditable={editTemplate}
          onBlur={(e) => handleQuestionForm(e, index, "options", i)}
        >
          {data}
        </span>
      </label>
    </div>
  );
};

export default QuestionOptions;
