const userGlobalConfig = {
  TOKEN: "ADMIN_LOGIN_TOKEN",
  USER_ID: "USER_ID",
  JOURNEY_ID: "JOURNEY_ID",
  SKILL_ID: "SKILL_ID",
  STEP_ID: "STEP_ID",
  SUB_STEP_ID: "SUB_STEP_ID",
  EXERCISE_ID: "EXERCISE_ID",
  TAB_TYPE: "TAB_TYPE",
};

export default userGlobalConfig;
