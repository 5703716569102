import React from "react";

const ScenarioOptions = ({
  i,
  data,
  editTemplate,
  item,
  index,
  handleQuestionForm,
  handleQuestionAns,
}) => {
  return (
    <div key={i}>
      <div className="checkbox-list">
        <input
          type="radio"
          className="d-none"
          name={`listradio_${index}`}
          id={`checkbox_${index}${i}`}
          value={i + 1}
          onChange={(e) =>
            editTemplate ? handleQuestionAns(e, index, "answer") : null
          }
          checked={i + 1 == item.answer}
        />
        <label htmlFor={`checkbox_${index}${i}`}>
          <span className="check-box-icon" />
          <span
            className="check-box-text"
            contentEditable={editTemplate}
            onBlur={(e) => handleQuestionForm(e, index, "options", i, "value")}
          >
            {data?.value}
          </span>
        </label>
      </div>
      <div class="reflection-item w-100 feedback-text-div border-0">
        <div class="reflection-title">
          <p className="correct-text">Feedback</p>
        </div>
        <div class="reflection-quote">
          <p
            className="feedback-inner-text"
            contenteditable={editTemplate ? "true" : "false"}
            onBlur={(e) =>
              handleQuestionForm(e, index, "options", i, "feedback")
            }
          >
            {data?.feedback}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ScenarioOptions;
