import React, { useState } from "react";

const Pagination = ({
  currentPage,
  totalPages,
  handlePrevPage,
  handleNextPage,
  handleRowLimitChange,
  countData,
}) => {
  const [rowLimit, setRowLimit] = useState(10);

  const handleChange = (event) => {
    setRowLimit(event.target.value);
    handleRowLimitChange(event.target.value);
  };

  return (
    <div className="pagination-button-wrapper">
      <label className="row-label">
        <span className="row-title">Rows per page:</span>
        <select
          value={rowLimit}
          onChange={handleChange}
          className="form-select bg-transparent"
        >
          <option value={15}>15</option>
          <option value={25}>25</option>
          <option value={35}>35</option>
          <option value={50}>50</option>
        </select>
      </label>
      <button
        className="pagination-button"
        onClick={() => handlePrevPage(currentPage)}
        disabled={currentPage === 1}
      >
        <i className="fa-solid fa-chevron-left"></i>
      </button>

      <span className="pagination-page-info">
        Page {currentPage} of {Math.ceil(totalPages / countData)}
      </span>

      <button
        className="pagination-button"
        onClick={() => handleNextPage(currentPage)}
        disabled={currentPage === Math.ceil(totalPages / countData)}
      >
        <i className="fa-solid fa-chevron-right"></i>
      </button>
    </div>
  );
};

export default Pagination;
