import * as Yup from "yup";

const editUserInitialValues = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  countryCode: "",
};
const editUserValidationSchema = Yup.object().shape({
  firstName: Yup.string().trim().required("First name is required*"),
  lastName: Yup.string().trim().required("Last name is required*"),
});
export { editUserInitialValues, editUserValidationSchema };
