/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { truncateText } from "../../utils/helpers";

export default function ExerciseCard({ data, onView }) {
  return (
    <div className="steps-content-blk cursor-pointer" onClick={() => onView()}>
      <div className="steps-card-img ">
        <img src={data?.image} alt="Image" />
      </div>
      <div className="steps-detail">
        <h3>{truncateText(data?.title)}</h3>
        <span>Time : {data?.extimated_time} min</span>
      </div>
    </div>
  );
}
