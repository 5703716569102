const userTableHeaderList = [
  {
    id: 1,
    name: "User Name ",
  },

  {
    id: 2,
    name: "Email",
  },
  {
    id: 3,
    name: "Phone Number ",
  },
  {
    id: 4,
    name: "Status",
  },
];
const journeyTableHeaderList = [
  {
    id: 1,
    name: "Image ",
  },

  {
    id: 2,
    name: "Name",
  },
  {
    id: 3,
    name: "Description ",
  },
  {
    id: 4,
    name: "Status",
  },
];

const journeyData = [
  {
    image: "../assets/logos/main-logo.svg",
    name: "Test",
    description: " shshs sfsfhs",
    status: 1,
  },
  {
    image: "../assets/logos/main-logo.svg",
    name: "Test",
    description: " shshs sfsfhs",
    status: 1,
  },
  {
    image: "../assets/logos/main-logo.svg",
    name: "Test",
    description: " shshs sfsfhs",
    status: 1,
  },
  {
    image: "../assets/logos/main-logo.svg",
    name: "Test",
    description: " shshs sfsfhs",
    status: 1,
  },
];
export { userTableHeaderList, journeyTableHeaderList, journeyData };
