/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useRef } from "react";
import ImagesUrl from "../../utils/constants/images";

const ReflectionComponent = ({
  item,
  index,
  editTemplate,
  removeResource,
  handleResourceText,
  handleFileInputMultiple,
  active,
  setActive,
  array,
}) => {
  const fileInput = useRef(null);

  const handleFileInput = (target) => {
    handleFileInputMultiple(index, target.files[0]);
    target.value = "";
  };
  const previewImageHandler = (item) => {
    if (item?.image?.added_image === "") {
      return ImagesUrl.shape_image;
    } else if (item?.image?.added_image instanceof File) {
      return URL.createObjectURL(item?.image.added_image);
    } else {
      return `https://leadherself-api.csdevhub.com/src/public/form_images/${item?.image.added_image}`;
    }
  };
  const handleSelectUploadImage = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  return (
    <div
      className={`resources-item ${
        active == index && "border border-success rounded-3"
      }`}
    >
      <div className="upload-img-blk resources-upld-box">
        <img
          className="preview-img"
          src={previewImageHandler(item, index)}
          alt="Image"
        />
        <div className="upload-img-file">
          <input
            type="file"
            className="d-none"
            ref={fileInput}
            onChange={({ target }) => handleFileInput(target)}
          />
          <label
            htmlFor="ImageUpload"
            onClick={editTemplate ? handleSelectUploadImage : null}
          >
            <i className="fa-solid fa-image" />
          </label>
        </div>
      </div>

      <div className="resources-content-blk">
        <div className="resources-info">
          <div className="resources-title">
            <p
              contentEditable={editTemplate}
              onBlur={(e) => handleResourceText(e, index, "title")}
            >
              {item?.title}
            </p>
          </div>
          <div className="resources-quote">
            <p
              contentEditable={editTemplate}
              onBlur={(e) => handleResourceText(e, index, "description")}
            >
              {item?.description}
            </p>
          </div>
          <div className="resources-by">
            <p
              contentEditable={editTemplate}
              onBlur={(e) => handleResourceText(e, index, "author")}
            >
              {item?.author}
            </p>
          </div>
        </div>
        <div className="resources-time">
          <p
            contentEditable={editTemplate}
            onBlur={(e) => handleResourceText(e, index, "time")}
          >
            {item?.time}
          </p>
        </div>
      </div>
      <div className="del-resource-item">
        {editTemplate ? (
          <button
            style={{ border: "0px", background: "#ffefe2" }}
            onClick={() => setActive(index)}
          >
            <i class="fa-solid fa-pen"></i>
          </button>
        ) : (
          <button
            style={{ border: "0px", background: "#ffefe2" }}
            onClick={() => setActive(index)}
          >
            <i class="fa-regular fa-eye"></i>
          </button>
        )}

        {index > 0 || (array?.length > 1 && editTemplate) ? (
          <button
            style={{ border: "0px", background: "#ffefe2" }}
            onClick={() => removeResource(index)}
          >
            <i className="fa-solid fa-trash-can" />
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default ReflectionComponent;
