import React from "react";

const Toggle = ({ leftValue, rightValue, handleClick, active }) => {
  return (
    <div>
      <button
        onClick={() => handleClick(leftValue)}
        style={{
          padding: "10px 20px",
          backgroundColor: active === "Steps" ? "#006972" : "#f1f1f1",
          color: active === "Steps" ? "white" : "#000",
          border: "none",
          borderRadius: "5px 0 0 5px",
          cursor: "pointer",
          width: "120px",
        }}
      >
        {leftValue}
      </button>
      <button
        onClick={() => handleClick(rightValue)}
        style={{
          padding: "10px 20px",
          backgroundColor: active === "Exercises" ? "#006972" : "#f1f1f1",
          color: active === "Exercises" ? "white" : "#000",
          border: "none",
          borderRadius: "0 5px 5px 0",
          cursor: "pointer",
          width: "120px",
        }}
      >
        {rightValue}
      </button>
    </div>
  );
};

export default Toggle;
