import { useMutation } from "react-query";
import { axiosInstance } from "../services/axiosTokenInstance";

const useDelete = (url, queryKey) => {
  const { isLoading, error, mutateAsync } = useMutation(
    queryKey,
    async (data) => {
      const result = await axiosInstance({
        method: "delete",
        url: url,
        data: data,
        params: data,
        headers: { "Content-Type": "application/json" },
      });
      return result.data;
    },
    {
      onError: (error) => {
        console.log(error);
      },
    }
  );

  return { isLoading, error, mutateAsync };
};

export default useDelete;
