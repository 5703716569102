import React from "react";

export default function TableHeader({ data }) {
  return (
    <thead>
      <tr>
        {data?.map((item, index) => {
          return (
            <th
              className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              key={index}
            >
              {item.name}
            </th>
          );
        })}
      </tr>
    </thead>
  );
}
