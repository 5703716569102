/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik";
import React, { useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import {
  editUserInitialValues,
  editUserValidationSchema,
} from "../../utils/validations/user";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { RoutePath } from "../../routes/routePath";
import usePut from "../../hooks/usePut";
import ENDPOINTS from "../../services/endPoints";
import QUERY_KEYS from "../../services/queryKeys";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import ImagesUrl from "../../utils/constants/images";

const EditUserDetail = () => {
  const userDetail = useLocation();
  const getUserData = userDetail?.state?.userDetail;

  const nav = useNavigate();
  const { mutateAsync: editUserMutateAsync, isLoading: isLoadingEditUser } =
    usePut(ENDPOINTS.USER_EDIT, QUERY_KEYS.USER_EDIT_QUERY_KEY);

  const customValidate = (values) => {
    const errors = {};
    if (!values.phoneNumber) {
      errors.phoneNumber = "Phone Number is required*";
    }

    return errors;
  };

  const {
    errors,
    handleChange,
    handleBlur,
    values,
    touched,
    setValues,
    resetForm,
    setFieldValue,
    handleSubmit,
  } = useFormik({
    initialValues: editUserInitialValues,
    validationSchema: editUserValidationSchema,
    validate: customValidate,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("user_id", getUserData?._id);
      formData.append("first_name", values?.firstName);
      formData.append("last_name", values?.lastName);
      formData.append("country_code", values?.countryCode);
      formData.append("phone_number", values?.phoneNumber);
      try {
        const response = await editUserMutateAsync(formData);
        if (response.status === true) {
          toast.success(response?.message);
          resetForm();
          nav(RoutePath.USER_DETAIL.path);
        } else {
          toast.error(response?.message);
        }
      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
  });
  useEffect(() => {
    setValues({
      email: getUserData?.email,
      phoneNumber: getUserData?.phone_number,
      firstName: getUserData?.first_name,
      lastName: getUserData?.last_name,
      countryCode: getUserData?.country_code,
    });
  }, [getUserData]);

  return (
    <div className="container-fluid py-4">
      <div className="row">
        <div className="card position-relative">
          <img
            className="back_btn"
            src={ImagesUrl.back_btn}
            alt=".."
            onClick={() => nav(-1)}
          />
          <div className="card-body px-4 pt-5">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group ">
                  <label
                    htmlFor="example-text-input"
                    className="form-control-label"
                  >
                    First Name
                  </label>
                  <input
                    className="form-control "
                    type="text"
                    placeholder="First Name"
                    name="firstName"
                    value={values.firstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {touched.firstName && errors.firstName && (
                  <p className="error">{errors.firstName}</p>
                )}
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    htmlFor="example-text-input"
                    className="form-control-label"
                  >
                    Last Name
                  </label>
                  <input
                    className="form-control "
                    type="text"
                    placeholder="Last Name"
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {touched.lastName && errors.lastName && (
                  <p className="error">{errors.lastName}</p>
                )}
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label
                    htmlFor="example-text-input"
                    className="form-control-label"
                  >
                    Email Address
                  </label>
                  <input
                    className="form-control"
                    value={values.email}
                    readOnly={true}
                    disabled={true}
                    name="email"
                    onChange={handleChange}
                    placeholder="Enter email address"
                    onBlur={handleBlur}
                  />
                </div>
                {touched.email && errors.email && (
                  <p className="error">{errors.email}</p>
                )}
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label
                    htmlFor="example-text-input"
                    className="form-control-label"
                  >
                    Phone Number
                  </label>
                  <PhoneInput
                    country={"eg"}
                    readOnly={getUserData?.phone_number === null ? false : true}
                    disabled={getUserData?.phone_number === null ? false : true}
                    enableSearch={true}
                    value={`${values.countryCode}${values.phoneNumber}`}
                    onChange={(value, data) => {
                      const rawPhone = value
                        .replace(/[^0-9]+/g, "")
                        .slice(data.dialCode.length);
                      setFieldValue("phoneNumber", rawPhone);
                      setFieldValue("countryCode", data.dialCode);
                    }}
                  />
                </div>
                {touched.phoneNumber && errors.phoneNumber && (
                  <p className="error">{errors.phoneNumber}</p>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <Link
                state={{ headerName: RoutePath.USER_DETAIL.title }}
                to={RoutePath.USER_DETAIL.path}
              >
                <button className="btn btn-sm btn-green  mt-3 mb-0">
                  Cancel
                </button>
              </Link>

              <button
                type="button"
                className="btn btn-sm bg-danger text-white mt-3 mb-0 mx-3 "
                onClick={handleSubmit}
                disabled={isLoadingEditUser}
              >
                {isLoadingEditUser ? (
                  <ClipLoader color="#0d6efd" size={15} />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUserDetail;
