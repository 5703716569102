import { RoutePath } from "../../routes/routePath";

const SidebarArray = () => {
  return [
    // {
    //   id: "1",
    //   name: RoutePath.DASHBOARD.title,
    //   icon: <i className="ni ni-tv-2 text-primary text-sm opacity-10" />,
    //   path: RoutePath.DASHBOARD.path,
    // },
    {
      id: "2",
      name: RoutePath.USER_MANAGEMENT.title,
      icon: <i class="ni ni-single-02 text-dark text-sm opacity-10"></i>,
      path: RoutePath.USER_MANAGEMENT.path,
    },
    {
      id: "3",
      name: RoutePath.JOURNEY_MANAGEMENT.title,
      icon: <i class="fa-solid fa-people-roof text-dark text-sm "></i>,
      path: RoutePath.JOURNEY_MANAGEMENT.path,
    },

    // {
    //   id: "4",
    //   name: RoutePath.SKILLS_MANAGEMENT.title,
    //   icon: <i class="fa-solid fa-book-open-reader text-dark text-sm "></i>,
    //   path: RoutePath.SKILLS_MANAGEMENT.path,
    // },
    {
      id: "5",
      name: RoutePath.CONTENT_MANAGEMENT.title,
      icon: <i class="fa-solid fa-pen-to-square text-dark text-sm "></i>,
      path: RoutePath.CONTENT_MANAGEMENT.path,
    },
  ];
};
export { SidebarArray };
